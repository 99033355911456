import type { GetServerSidePropsContext } from 'next';
import type { QueryFunctionContext } from 'react-query';

import queryStringify from '@/utils/url/queryStringify';
import http from './http';

/**
 * @function fetcher
 * @param {any} options
 * @param {any} context
 */
const fetcher = async (options: QueryFunctionContext) => {
  const { queryKey = [], meta = {} } = options;
  const context = meta.context as GetServerSidePropsContext | undefined;
  const [rpath, rparams] = queryKey;
  const path = rpath ? `${rpath}` : '';
  const params = rparams ? (rparams as Record<string, unknown>) : {};
  const pstring = queryStringify(params);
  const response = await http({ path, params: pstring }, context);
  return Promise.resolve(response);
};

export default fetcher;
